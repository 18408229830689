/**
* WSLR Admin Styles
*/

// colors
$black: #000000;
$white: #ffffff;
$lightgrey: #eff1f5;
$shadowgrey: #dfe3ec;
$grey: #6c757d;
$cigreen: #00ffea;
$cigreendark: #00e2cf;
$red: #ff0000;



// theme styling

html,
body {
  font-family: Overpass;
}

*:focus,
*:focus-visible,
*:focus-within {
  outline-color: $cigreen !important;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 20vh;
  width: 20vw;
  background-color: $white;
  top: 50%;
  bottom: unset;
  left: 50%;
  right: unset;
  transform: translate(-50%, -50%);
}

.wslr-myslideshowdata .spinner {
  position: relative;
  display: block;
  justify-content: center;
  height: 10vh;
  width: 10vw;
  top: 0;
  left: 0;
  transform: translate(0,0);
  background-color: $white;
  margin: 2vh 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.dropdown-menu {
  left: unset;
  right: 0;
}

.navbar-light.bg-light .nav-link {
  color: $black;
}
.navbar {
  background-color: $lightgrey;
  padding: 1.375rem 0;
  box-shadow: 0 1px 2px 0 $shadowgrey;
}
.navbar .router-link-exact-active {
  color: $black;
  border-bottom: 3px solid $cigreen;
}
.navbar-brand {
  margin-right: 60px;
}
.navbar-nav .nav-link {
  color: $black;
  padding: 0 0 0.5rem 0;
  box-sizing: border-box;
  margin: 0 40px 0 0;
  font-weight: 400;
}
.navbar-nav .nav-link:hover {
  color: #000104;
}
.navbar-nav .btn {
  min-width: 145px;
}
.nav-user-profile {
  border-radius: 50% 50%;
  max-width: 3.785rem;
  height: auto;
  box-shadow: 0 0 4px 0 $shadowgrey;
}
.nav-item.dropdown .dropdown-toggle {
  margin: 0;
}
.nav-item.dropdown .dropdown-name {
  font-weight: 600;
}
.nav-item.dropdown .router-link-exact-active {
  border: 0;
}
.nav-item.dropdown .dropdown-header {
  border-bottom: 1px solid $shadowgrey;
  font-size: 1rem;
  font-weight: 600;
  color: $black;
}
.nav-item.dropdown .dropdown-item {
  border-bottom: 1px solid $shadowgrey;
  padding: 0.55rem 1.5rem;
}
.nav-item.dropdown .dropdown-item .icon {
  margin-right: 8px;
  vertical-align: middle;
}
.nav-item.dropdown .dropdown-item:first-child:hover,
.nav-item.dropdown .dropdown-item:first-child:active {
  background: inherit;
}
.nav-item.dropdown .dropdown-item:active {
  background-color: $cigreendark;
  outline: none;
}
.nav-item.dropdown .dropdown-item:last-child {
  border-bottom: 0;
}
.navbar {
  padding: 1.375rem 0;
}
@media (min-width: 576px) {
  .container {
      max-width: inherit;
  }
}
@media (min-width: 992px) {
  .container {
      max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
      max-width: 1140px;
  }
}
@media (max-width: 767px) {
  .nav-item {
      margin-bottom: 1rem;
  }
  .nav-item .nav-link {
      padding: 0 0 0 16px;
  }
  .nav-item .nav-link.router-link-exact-active {
      border-bottom: 0;
      border-left: 3px solid $cigreen;
      padding-left: 13px !important;
      vertical-align: middle;
  }
  .navbar-brand {
      margin-left: 2.5rem;
      vertical-align: top;
  }
  .navbar-toggler {
      margin-right: 2.5rem;
  }
  .navbar-nav {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
  }
  .navbar-nav:first-child {
      margin-top: 1.5em;
  }
  .navbar-nav:last-child {
      background: #fff;
      box-shadow: 0 -1px 2px 0 $shadowgrey;
      margin: 1.5em 0 0 0;
      padding: 1.5em 2.5rem;
  }
  .navbar-nav:last-child li {
      margin-bottom: 1em;
  }
  .navbar-nav:last-child li .icon {
      margin-right: 1em;
      vertical-align: middle;
  }
  .navbar-nav:last-child li a {
      vertical-align: middle;
      color: $black;
  }
  .navbar-nav .user-info img {
      margin-right: 1em;
  }
  .navbar-nav .btn-link {
      padding: 0;
      color: $black;
      min-width: 0;
  }
}
.logo {
  width: 6rem;
  height: 2.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  img {
    width: 6.5rem;
    margin-top: -1.5rem;
  }
}
footer {
  background: $lightgrey;
  box-shadow: 0 -1px 2px 0 $shadowgrey;
  text-align: center;
  padding: 32px;
  font-size: .75rem;
}
footer p {
  margin-bottom: .1rem;
}
.hero {
  max-width: 650px;
  margin: 0 auto;
}
.hero p.lead {
  margin-bottom: 5rem;
  font-size: 1.45rem;
}
.hero p.lead a {
  font-weight: 500;
}
.hero .app-logo {
  max-width: 7.5rem;
}
.next-steps {
  padding: 0 32px;
}
.next-steps h2 {
  margin-bottom: 4rem;
}
.next-steps .row {
  margin-bottom: 3rem;
}
@media (max-width: 768px) {
  .next-steps .row {
      margin-bottom: 0;
  }
  .next-steps h6 {
      margin-top: 1.5rem;
  }
}
pre {
  width: 100%;
}
.profile-header {
  text-align: center;
  margin-bottom: 3rem;
}
.profile-header .lead {
  font-size: 1.25rem;
}
.profile-header .profile-picture {
  box-shadow: 0 0 4px 0 $shadowgrey;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .profile-header {
      text-align: left;
  }
  .profile-header .profile-picture {
      margin-bottom: inherit;
  }
}
.result-block-container {
  position: relative;
  min-height: 300px;
}
.result-block {
  top: -10px;
  position: absolute;
  opacity: 0;
  transition: all 0.3s;
}
.result-block.show {
  opacity: 1;
  top: 0;
}
@media only screen and (min-width: 768px) {
  .result-block {
      min-width: 700px;
  }
}
html {
  font-size: 16px;
}
body {
  color: $black;
}
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
h6.muted {
  color: $grey;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}
a,
a:hover {
  color: $cigreen;
}
.btn-primary {
  background-color: $cigreen;
  color: $black;
  font-weight: 900;
  border: none;
  &:hover, 
  &:active,
  &:visited,
  &:focus {
    background-color: $cigreendark !important;
  }
  &:focus {
    box-shadow: 0 0 0 .2rem rgba($cigreen, 0.5) !important;
  }
  &.disabled,
  &:disabled {
    background-color: $lightgrey;
    border-color: $lightgrey;
  }
}
.btn {
  border-radius: 0.1rem;
}

input {
  width:100%;
  &:focus {
    outline-color: $cigreen;
  }
}

.hint {
  color: $grey;
  font-size: .75rem;

  &.error {
    color: $red;
  }
}

.text-highlight {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  padding-left: 5px;
  padding-right: 5px;
  background: linear-gradient(to top, transparent 0, transparent 30%, $cigreen 31%, $cigreen 70%, transparent 71%);
  color: $black;

  &::selection,
  &::-moz-selection {
    background: linear-gradient(to top, transparent 0, transparent 30%, $cigreendark 31%, $cigreendark 70%, transparent 71%);
  }

  &:hover {
    color: $black;
  }
}

.centered {
  text-align: center;
}

.red {
  color: $red;
}
.green {
  color: $cigreen;
}
